(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("esprima"));
	else if(typeof define === 'function' && define.amd)
		define(["esprima"], factory);
	else if(typeof exports === 'object')
		exports["SwaggerEditorBundle"] = factory(require("esprima"));
	else
		root["SwaggerEditorBundle"] = factory(root["esprima"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__210__) {
return 